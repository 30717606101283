<template>
    <div style="padding-bottom: 50px;">
        <van-sticky>
            <div style="overflow: hidden;position: relative;height: 44px;width: 100%;">
                <div :style="'width: 200%;display: flex;position: absolute;top:0;transition: width 2s;' + (searching?'left: -100%':'left: 0') + ';'">
                    <div style="width: 50%;">
                        <div style="display: flex;background: #FEC919">
                            <div style="height: 44px;width:88px;position: relative;">
                                <img src="../../assets/img/qianliang/logo3.png" style="width:60px;height:26px;position: absolute;top: 8px;left: 14px;" class="qianliang-card-icon">
                            </div>
                            <van-tabs background="#FEC919" color="#000" title-active-color="#000" title-inactive-color="#000" v-model="active" style="flex: 1 1 auto;">
                                <van-tab title="全部" :name="-1"></van-tab>
                                <van-tab title="未签到" :name="1"></van-tab>
                            </van-tabs>
                            <van-icon name="search" size="26" color="#fff" style="padding: 9px 15px 0;" @click="toSearch"></van-icon>
                        </div>
                    </div>
                    <div style="width: 50%;" class="dashboard-search">
                        <van-search v-model="title" show-action background="#FEC919" placeholder="请输入你需要搜索的内容" shape="round" @search="search" @cancel="reloadData"/>
                    </div>
                </div>
            </div>
        </van-sticky>

<!--        <iframe src="http://official.chenxiaoxiang.top/" style="width: 100%;height: 500px;border: none" sandbox="allow-scripts allow-same-origin allow-popups"></iframe>-->
<!--        <iframe src="https://m.qlchat.com/wechat/page/topic-intro?topicId=2000019800203504" style="width: 100%;height: 500px;border: none" sandbox="allow-scripts allow-same-origin allow-popups"></iframe>-->
<!--        <iframe src="https://wx.vzan.com/live/tvchat-544222030?v=1684392190426" style="width: 100%;height: 500px;border: none" sandbox="allow-scripts allow-same-origin allow-popups"></iframe>-->

<!--        <div style="position: absolute;bottom: 0;right: 20px;background: pink;height: 10px;width: 10px;"></div>-->

        <div id="containerId"></div>
        <van-cell-group>
            <van-list v-model="loading" :finished="finished" @load="loadData">
                <van-cell  v-for="(item,index) in pageList" :key="index">
                    <div style="display: flex;">
                        <div v-if="item.fileId" style="flex: 0 0 120px;margin-right: 16px;width: 120px;height:90px;border-radius: 10px;overflow: hidden">
                            <van-image :src="'https://oss.ttbaoxiu.com/' + item.fileId + '?x-oss-process=image/resize,m_fill,w_120,h_90'" style="width: 120px;height: 90px;"></van-image>
                        </div>
                        <div style="flex: 1 1 auto">
                            <div style="overflow:hidden;text-overflow:ellipsis;display:-webkit-box;-webkit-line-clamp:2;-webkit-box-orient:vertical;height: 48px;">{{item.title}}</div>
                            <div style="display: flex;justify-content: space-between;align-items: center;margin-top: 14px;font-size: 13px;">
                                <div>奖励：<span class="text-color text-color2">{{item.liangpiaoNum}}</span> 黑钻</div>
                                <div class="button-area">
                                    <div class="button" v-if="item.showStatus === '1'" @click="signIn(item.id)" style="background-color: rgba(255, 255, 255, 1);color: rgba(16, 16, 16, 1);border: 1px solid rgba(242, 203, 38, 1);line-height: 20px;padding: 0 11px;">
                                        &nbsp;签到（{{(item.clickSpotNum?item.clickSpotNum:0)}}/{{item.spotNum}}）
                                    </div>
                                    <div v-else style="display: flex;">
                                        <div class="button">已签到</div>
                                        <div class="button" style="background-color: rgba(255, 255, 255, 1);color: rgba(16, 16, 16, 1);margin-left: 5px;border: 1px solid rgba(187, 187, 187, 1);line-height: 20px;" @click="showSpots(item.id)">查看</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </van-cell>
                <template #finished>
                    <span v-if="pageList.length">没有更多了</span>
                </template>
            </van-list>
            <div v-if="finished && !pageList.length" style="width: 100%;display: flex;flex-direction: column;align-items: center;color: #C2C2C2;padding-bottom: 20px;">
                <img src="../../assets/img/qianliang/success.png" style="width: 50%;">
                <p v-if="title">当前没有您要查找的活动或文章</p>
                <p v-else>{{(active===-1?'当前签到已全部完成，请等待新活动发布':'当前签到已全部完成，请等待新活动发布')}}</p>
            </div>
        </van-cell-group>

        <div id="banner_2_0"></div>

<!--        <iframe src="https://www.baidu.com" style="width: 100%;height: 500px;border: none" sandbox="allow-scripts allow-same-origin allow-popups"></iframe>-->
<!--        <div style="position: absolute;bottom: 0;right: 20px;background: pink;height: 10px;width: 10px;"></div>-->

<!--        <van-button @click="clearStorage">清理缓存</van-button>-->

        <van-dialog v-model="showSpotsDialog" :showConfirmButton="false" :show-cancel-button="true" cancel-button-text="关闭" cancelButtonColor="#888888" closeOnClickOverlay>
            <template #title>
                <div style="padding: 0 15px;">
                    <van-icon name="info" color="rgba(242, 203, 38, 1)"/>
                    <span style="font-size: 12px;color: red;">&nbsp;为了防止活动下架后找不到文章，建议先将文章加入收藏永久保存，然后再在收藏栏中查看</span>
                </div>
                <div style="margin-top: 20px;max-height: 300px;overflow: auto;">
                    <van-cell-group>
                        <van-cell v-for="(item,index) in spotDTOList" :key="index">
                            <div style="display: flex;justify-content: center;align-items: center;">
                                <div style="flex: 1 1 auto;">
                                    <a :href="item.accessUrl" style="color: #0099cc;text-decoration: underline;">{{item.spotTitle}}</a>
                                </div>
                                <van-button size="mini"
                                            v-if="item.collectionStateCode=== '0'"
                                            @click="collectionAdd(item.fkClickActivityId,item.id)"
                                            style="flex: 0 0 60px;width: 60px;margin-left: 10px;border-radius: 4px;border: 1px solid rgba(242, 203, 38, 1);color: rgba(242, 203, 38, 1);" >加入收藏</van-button>
                                <van-button size="mini"
                                            v-else
                                            disabled
                                            style="flex: 0 0 60px;width: 60px;margin-left: 10px;border-radius: 4px;">已加入</van-button>
                            </div>
                        </van-cell>
                    </van-cell-group>
                </div>
            </template>
        </van-dialog>

<!--        <van-cell-group inset class="qianliang-card">-->
<!--            <div class="qianliang-card-header" @click="toAll">-->
<!--                <img src="../../assets/img/qianliang/arcoDesign-pen@1x.png" class="qianliang-card-icon">-->
<!--                <div class="qianliang-card-title">签到活动</div>-->
<!--                <div class="qianliang-card-more">查看全部<van-icon name="arrow" /></div>-->
<!--            </div>-->
<!--            <div class="qianliang-card-body">-->
<!--                <div class="qianliang-card-qiandao" v-for="(item,index) in activity" :key="index" v-if="item.activityStatus === '1' && item.showStatus === '1'">-->
<!--                    <div>-->
<!--                        <div>{{item.title}}</div>-->
<!--                        <div style="font-size: 12px;">-->
<!--                            <div>奖励：<span class="text-color">{{item.liangpiaoNum}}</span> 黑钻</div>-->
<!--                            <div>截止：{{item.endTime.substr(5,5)}}</div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div>-->
<!--                        <van-button size="small" class="qianliang-button qianliang-button-check" v-if="item.showStatus === '1'" @click="signIn(item.id)">-->
<!--                            签到（{{(item.clickSpotNum?item.clickSpotNum:0)}}/{{item.spotNum}}）-->
<!--                        </van-button>-->
<!--                        <van-button size="small" class="qianliang-button qianliang-button-check qianliang-button-gray" disabled v-else>已完成</van-button>-->
<!--                    </div>-->
<!--                </div>-->
<!--&lt;!&ndash;                <div v-else-if="!activity.length" style="width: 100%;display: flex;flex-direction: column;align-items: center;color: #C2C2C2">&ndash;&gt;-->
<!--&lt;!&ndash;                    <img src="../../assets/img/qianliang/success.png" style="width: 50%;">&ndash;&gt;-->
<!--&lt;!&ndash;                    <p>当前签到已全部完成，请等待新活动发布</p>&ndash;&gt;-->
<!--&lt;!&ndash;                </div>&ndash;&gt;-->
<!--            </div>-->
<!--        </van-cell-group>-->
        <tabbar active="dashboard"></tabbar>
    </div>
</template>

<script>


import {Toast, Dialog} from 'vant';
import {mapState} from 'vuex';
import service from "../../utils/request";
import Tabbar from "./tabbar";

export default {
    name: "dashboard",
    computed: {
        ...mapState(['common'])
    },
    components: {Tabbar},
    data() {
        return {
            // 顶部
            active: -1,
            searching:false,

            title:'',

            pageList:[],  // 全部
            pageNo:0,
            totalCount:0,
            totalPage:0,
            loading: false,
            finished: false,

            spotDTOList:[],
            showSpotsDialog:false,
        }
    },
    created() {
        let that = this
        if(!localStorage.getItem('xToken') || !localStorage.getItem('userInfo')){
            this.$router.replace('/verify')
        }
        // 验证是否已绑定
        if(JSON.parse(localStorage.getItem('userInfo')).bindStatus === '0'){
            this.$router.replace('/verify')
        }
        // window.addEventListener("popstate", function (e) {
        //     // if (event.persisted || (window.performance && window.performance.navigation.type === 2))  {
        //     that.checkRead()
        //     // }
        // });
    },
    watch:{
        active(newValue){
            // console.log('状态：')
            // console.log(newValue)
            this.reloadData()
            // console.log(this.finished)
        }
    },
    async mounted() {
        let that = this

        document.body.scrollTop = document.documentElement.scrollTop = 0;

        // 检查打卡成功
        let u = navigator.userAgent;
        let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
        if(isiOS){
            window.onpageshow = (event) => {
                that.checkRead()
            };
        } else {
            that.checkRead()
        }

        // this.checkRead()
    },
    activated () {
        // 检查打卡成功
        this.checkRead()
        // 重新加载页面
        this.reloadData()
    },
    methods: {
        toSearch(){
            this.searching = true
        },
        reloadData(){
            this.searching = false;
            this.title = '';
            this.search()
        },
        search(){
            this.pageList=[];
            this.pageNo=0;
            this.totalCount=0;
            this.totalPage=0;
            this.finished = false;
            this.loadData()
        },
        loadData() {
            let that = this
            // 分别查询
            if(this.totalPage > 0 && this.totalPage === this.pageNo){
                this.$toast('已经到底啦');
                return
            }
            this.pageNo += 1
            this.post('/activity/queryPageList2',{
                size: 20,
                current: this.pageNo,
                title:this.title,
                queryStatus:this.active,
            }).then(result => {
                // console.log(result)
                for (let i = 0; i < result.data.result.length; i++) {
                    this.pageList.push(result.data.result[i]);
                }
                that.loading = false;
                this.totalCount = result.data.totalCount;
                this.totalPage = result.data.totalPage;
                if(this.pageNo >= this.totalPage){
                    this.finished = true;
                }
                // console.log(this.finished)
            })
        },
        // toAll(){
        //     this.$router.push('/all')
        // },
        // 查看历史打卡的活动
        showSpots(id){
            let that = this
            this.post('/activity/queryDetail',{
                id:id,
                lookCollectionFlag:1,
            }).then(result => {
                if (result.respCode === '0') {
                    that.spotDTOList = result.data.spotDTOList
                    that.showSpotsDialog = true
                } else {
                    console.log(result)
                    that.$dialog.alert({
                        title: '提示',
                        message: result.respMsg,
                    })
                }
            }).catch(e=>{
                console.log(e)
                that.$dialog.alert({
                    title: '提示',
                    message: "数据异常，查看失败",
                })
            })
        },
        /**
         * 检查打卡是否成功
         */
        checkRead(){
            let that = this
            // 检查是否刚刚阅读了信息
            let signInTime = localStorage.getItem('signInTime');
            let fkClickActivityId = localStorage.getItem('fkClickActivityId');
            let fkClickActivitySpotId = localStorage.getItem('fkClickActivitySpotId');
            let accessUrl = localStorage.getItem('accessUrl');
            // 清除阅读信息记录
            localStorage.removeItem('signInTime');
            localStorage.removeItem('fkClickActivityId');
            localStorage.removeItem('fkClickActivitySpotId');
            localStorage.removeItem('accessUrl');
            // 没有阅读信息记录，结束
            if(!signInTime || !fkClickActivityId || !fkClickActivitySpotId || !accessUrl) return;

            let now = Math.floor(new Date().getTime()/1000);
            signInTime = parseInt(signInTime)
            // 超过300秒：阅读成功
            let intervalTime = now - signInTime;
            if(intervalTime > 300){
                // 打卡成功
                this.post('/activity/order/clockSpot2',{
                    fkClickActivityId:fkClickActivityId,
                    fkClickActivitySpotId:fkClickActivitySpotId,
                    intervalTime:intervalTime,
                }).then(result=>{
                    if(result.respCode === "0"){
                        console.log(result)
                        setTimeout(function(){
                            that.search()
                        }, 1000)
                        if(result.data.allClickFlag === "1"){
                            // 提示任务完成
                            that.$dialog.confirm({
                                title: '恭喜收获黑钻',
                                message: "签到完成。如果你喜欢这篇文章，请点击下方加入收藏按钮，将文章永久保存",
                                confirmButtonText:'加入收藏',
                            }).then(() => {
                                that.collectionAdd(fkClickActivityId,fkClickActivitySpotId)
                            });
                        } else if (result.data.id) {  // 当前已打卡，还有下一篇
                            // 提示阅读成功
                            that.$dialog.confirm({
                                title: '阅读成功',
                                message: "如果你喜欢这篇文章的话，可以点击下方加入收藏按钮，将文章永久保存",
                                confirmButtonText:'加入收藏',
                            }).then(() => {
                                that.collectionAdd(fkClickActivityId,fkClickActivitySpotId)
                            });
                        } else {
                            // 提示阅读失败
                            that.$dialog.alert({
                                title: '阅读失败',
                                message:result.respMsg,
                            }).then(()=>{
                                if(result.respCode === 'EORDER-TOKEN'){
                                    localStorage.clear()
                                    // that.$router.replace('/verify')
                                    // location.replace(window.location.href)
                                    let url = window.location.href
                                    location.replace(url.substring(0,url.lastIndexOf('/')) + '/')
                                }
                            })
                        }
                    } else {
                        // 提示阅读失败
                        that.$dialog.alert({
                            title: '阅读失败',
                            message:result.respMsg,
                        }).then(()=>{
                            if(result.respCode === 'EORDER-TOKEN'){
                                localStorage.clear()
                                // that.$router.replace('/verify')
                                // location.replace(window.location.href)
                                let url = window.location.href
                                location.replace(url.substring(0,url.lastIndexOf('/')) + '/')
                            }
                        })
                    }
                }).catch(e=>{
                    console.log(e)
                    // 提示阅读失败
                    that.$dialog.alert({
                        title: '提示',
                        message: "数据异常，阅读失败",
                    })
                })
            } else {
                // 提示阅读失败
                that.$dialog.alert({
                    title: '阅读失败',
                    message: "阅读时间少于5分钟！请点击签到按钮，重新阅读文章",
                    confirmButtonText:'重新阅读',
                }).then(()=>{
                    // that.readArticle(fkClickActivityId, fkClickActivitySpotId, accessUrl)
                })
            }

        },
        collectionAdd(fkClickActivityId,fkClickActivitySpotId){
            this.post('/collecton/add',{
                fkClickActivityId:fkClickActivityId,
                fkClickActivitySpotId:fkClickActivitySpotId,
                // intervalTime:intervalTime,
            }).then(result=> {
                if (result.respCode === "0") {
                    Toast.success('加入收藏成功')
                    this.showSpotsDialog = false;
                    this.search();
                } else {
                    Toast.fail(result.respMsg)
                }
            })
        },
        /**
         * 查询签到活动详情
         * @param id 活动id
         */
        async signIn(id){
            let that = this
            // 查询活动详情
            let activityDetail = await this.queryDetail(id);
            console.log(activityDetail);
            // 查询到活动
            if (!activityDetail) return;
            // 判断用户是否已经参与了活动
            if(activityDetail.clickStateCode === '-1'){
                // 如果没有参与，那么先参与活动
                if(!await this.participate(id)){
                    return
                }
            }

            // 阅读文章
            if(!activityDetail.clickSpotNum || activityDetail.clickSpotNum < activityDetail.spotNum){
                let list = activityDetail.spotDTOList;
                for(let i = 0; i < list.length; i++){
                    if(list[i].clickStateCode === '0'){
                        console.log('跳转链接')
                        console.log(list[i].accessUrl)
                        that.readArticle(list[i].fkClickActivityId,list[i].id,list[i].accessUrl)
                        break;
                    }
                }
            }
        },
        /**
         * 阅读文章（跳转）
         * @param fkClickActivityId 活动id
         * @param fkClickActivitySpotId 文章id
         * @param accessUrl 文章url
         */
        readArticle(fkClickActivityId, fkClickActivitySpotId, accessUrl){
            // 记录当前的时间和打卡的id
            localStorage.setItem('fkClickActivityId', fkClickActivityId);
            localStorage.setItem('fkClickActivitySpotId', fkClickActivitySpotId)
            localStorage.setItem('accessUrl', accessUrl)
            localStorage.setItem('signInTime', Math.floor(new Date().getTime()/1000).toString())
            // 跳转
            // this.$router.push('/redirect?rand='+ Math.round(Math.random()*99999999999999))
            // localStorage.setItem('accessUrlJump',accessUrl)
            // let that = this
            // setTimeout(function (){
            //     that.$router.push('/redirect/'+ Math.round(Math.random()*99999999999999) + '?rand='+ Math.round(Math.random()*99999999999999))
            // },10)
            document.location.href = accessUrl
        },
        queryDetail(id){
            let that = this
            return new Promise((resolve, reject) => {
                // 查询活动详情，如果已经参与活动直接跳转到活动页面
                this.post('/activity/queryDetail',{
                    id:id
                }).then(result => {
                    if(result.respCode === '0'){
                        resolve(result.data)
                    } else {
                        that.$dialog.alert({
                            title: '提示',
                            message: result.respMsg,
                        }).then(()=>{
                            that.loadData()
                            resolve(false)
                        })
                    }
                }).catch(e=>{
                    console.log(e);
                    that.$dialog.alert({
                        title: '提示',
                        message: "查询失败",
                    }).then(()=>{
                        that.loadData()
                        resolve(false)
                    })
                })
            })
        },
        // 参与活动
        participate(id){
            let that = this
            return new Promise((resolve, reject) => {
                // 参与活动
                this.post('/activity/order/participate2',{
                    fkClickActivityId:id
                }).then(result => {
                    if(result.respCode === '0'){
                        console.log(result.data)
                        console.log('参与成功')
                        resolve(true)
                    } else {
                        that.$dialog.alert({
                            title: '提示',
                            message: result.respMsg,
                        }).then(()=>{
                            if(result.respCode === 'EORDER-TOKEN'){
                                localStorage.clear()
                                // that.$router.replace('/verify')
                                let url = window.location.href
                                location.replace(url.substring(0,url.lastIndexOf('/')) + '/')
                            } else {
                                that.loadData()
                            }
                            resolve(false)
                        })
                    }
                }).catch(e=>{
                    console.log(e);
                    that.$dialog.alert({
                        title: '提示',
                        message: "查询失败",
                    }).then(()=>{
                        that.loadData()
                        resolve(false)
                    })
                })
            })

        },

        clearStorage(){
            // 授权相关信息
            localStorage.removeItem('userInfo');
            localStorage.removeItem('xToken')
            localStorage.removeItem('completeUrl2')
            // 打卡相关信息
            localStorage.removeItem('fkClickActivityId')
            localStorage.removeItem('fkClickActivitySpotId')
            localStorage.removeItem('accessUrl')
            localStorage.removeItem('signInTime')
            this.$dialog.alert({
                title: '提示',
                message: "缓存清理成功，请手动关闭并重新打卡当前页面",
            })
        },
    },
}
</script>

<style scoped>
.fixed-bottom{
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 53px;
    background-color: rgba(255, 255, 255, 1);
    color: rgba(16, 16, 16, 1);
    font-size: 14px;
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.fixed-bottom-left{
    padding-left: 15px;
    line-height: 17px;
    color: rgba(136, 136, 136, 1);
    font-size: 12px;
    text-align: left;
    font-family: PingFang SC;
}
.fixed-bottom-right{
    width: 150px;
    color: rgba(136, 136, 136, 1);
    font-size: 14px;
    text-align: center;
    font-family: PingFang SC;
}
</style>
