<template>
    <van-tabbar v-model="tab" active-color="#FEC919" inactive-color="#888888">
        <van-tabbar-item name="dashboard" icon="home-o">首页</van-tabbar-item>
        <van-tabbar-item name="collection" icon="label-o">收藏</van-tabbar-item>
        <van-tabbar-item name="team" icon="friends-o">团队</van-tabbar-item>
        <van-tabbar-item name="mine" icon="manager-o">我的</van-tabbar-item>
    </van-tabbar>
</template>

<script>
export default {
    name: "tabbar",
    props:{
        active: '',
    },
    computed:{
        tab:{
            get(){
                return this.active
            },
            set(newValue){
                console.log(newValue)
                if(newValue !== this.active){
                    this.$router.replace('/' + newValue)
                }
            }
        }
    },
}
</script>

<style scoped>

</style>
